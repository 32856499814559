<script>
  /**
   * Styled rich text, which should be composed of other components / HTML tags including headings, paragraphs, lists, links, and more.
   */
  export default {
    provide() {
      return {
        BaseRichText: this,
      }
    },
    props: {
      /** The font size for paragraphs and lists. */
      fontSize: {
        type: String,
        default: 'base',
        validator: (value) => ['2xs', 'xs', 'sm', 'base', 'lg', 'xl', '4xl'].includes(value),
      },
      /** The leading for paragraphs and lists. */
      leading: {
        type: String,
        default: 'snug',
        validator: (value) => ['tight', 'snug', 'normal', 'none'].includes(value),
      },
    },
  }
</script>

<template>
  <!-- for purgecss: text-2xs text-sm text-base text-lg text-xl text-4xl -->
  <!-- for purgecss: leading-tight leading-snug leading-normal leading-none -->
  <div :class="`rich-text font-normal text-${fontSize} leading-${leading}`">
    <!-- @slot The rich text. Should contain any of the following tags or components: `<p>`, `<BaseHeading>`, `<BaseList>`. -->
    <slot />
  </div>
</template>

<style lang="postcss" scoped>
  .rich-text {
    & >>> h1,
    & >>> h2,
    & >>> h3,
    & >>> h4,
    & >>> h5,
    & >>> h6,
    & >>> p,
    & >>> ul,
    & >>> ol {
      & + h1,
      & + h2,
      & + h3,
      & + h4,
      & + h5,
      & + h6,
      & + p,
      & + ul,
      & + ol {
        margin-top: 1em;
      }
    }
  }
</style>
